import Head from "@/Molecules/Inertia/Head";
import Providers from "@/providers";
import {usePage} from "@inertiajs/react";
import React, {useEffect, useState} from "react";
import StorybookWindow from "@/types/StorybookWindow";
import {CommercialPageType} from "@/Atoms/Commercial";
import useKaChing from "@/helpers/useKaChing";
import UpButton from "@/Molecules/UpButton/UpButton";
import {MetaTag} from "@/types/MetaTab";
import Header, {HeaderAuthProps} from "@/Organisms/Header/Header";
import Footer from "@/Organisms/Footer/Footer";
import {bool} from "prop-types";
import useHarvest from "@/helpers/useHarvest";


export default ({children, title, fullTitle, metaTags, pageType, adUnit, harvestId}: {
  children: any,
  title?: string,
  fullTitle?: string,
  metaTags?: MetaTag[]
  pageType?: CommercialPageType,
  adUnit?: string,
  harvestId?: number|string,
}) => {

  const win = window as StorybookWindow

  const useSafePage = (win.IS_STORYBOOK)
    ? {url: 'dummy-url', component: null, props: {
      auth: {},
        legacy_widget: bool
      }}
    : usePage();

  const {url, component, props} = useSafePage;

  const auth: HeaderAuthProps = props.auth as HeaderAuthProps;
  const legacyWidget = !!props.legacy_widget;

  const {initKaching, destroyKaching} = useKaChing();

  useEffect(() => {

    if (!win.IS_STORYBOOK) {
      // Load page advertisements
      const pageTypeValue = pageType || CommercialPageType.Detail;
      if (adUnit) {
        setTimeout( () => {
          initKaching(url, pageTypeValue, adUnit)
        }, 1)// render a frame later to allow react based ads to be detected
      }
    }
    return () => {
      destroyKaching();
    };


  }, []);


  const dataset = document.querySelector('body')?.dataset
  if (dataset) {
    const harvestObj = harvestId ? {
      postID: harvestId,
      postPublishDate: '2024-08-27T14:00:28+00:00',
    } : {};
    dataset.trackPage = JSON.stringify(harvestObj)
  }
  useHarvest();


  if (fullTitle) {
    title = fullTitle
  } else {
    const appName = import.meta.env.VITE_APP_NAME || 'Laravel';
    title = `${title} - ${appName}`
  }

  return <>
    <Head>
      {title && <title>{title}</title>}
      {metaTags && metaTags.map(({name, content}, index) => <meta key={index} name={name} content={content}/>)}
    </Head>
    {!legacyWidget && <Header auth={auth} />}
    {children}
    {!legacyWidget && <UpButton/>}
    {!legacyWidget && <Footer />}
  </>
}
